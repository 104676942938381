.header {
  background-color: transparent;
  width: 100%;
  height: 64px;
}
.account {
  display: flex;
  justify-content: flex-end;
}

.menu-container {
  width: 100%;
  display: flex;
  align-items: center;
}
.menu {
  display: flex;
  flex: 1;
}

.search-user {
  width: 500px;
}
.logo {
  background-image: url('../../../resources/images/logo.png');
  width: 40px;
  height: 40px;
  background-size: cover;
  margin: 10px 5px;
  margin-right: 30px;
}

@media screen and (max-width: 768px) {
  .search-user {
    display: none;
  }
  .menu-container {
    display: block;
  }
  .header {
    padding: 5px;
  }
  .menu {
    position: absolute;
    top: 0;
    right: 50px;
    left: 60px;
  }
  .search-user {
    display: none;
  }
  .user-account {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;

    &__name {
      display: none;
    }
    &__icon {
      margin: 0;
    }
  }
}
